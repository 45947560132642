import "primereact/resources/themes/saga-orange/theme.css"
import "primereact/resources/primereact.min.css"
import "primeicons/primeicons.css"
import 'primeflex/primeflex.css';
import * as React from "react"
import {PageProps} from "gatsby";
import {BlacklistPage} from "../components/BlacklistPage";


export default function Blacklist_1(props: PageProps) {
    return <BlacklistPage id={1} {...props}/>
}
